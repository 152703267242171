import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from 'axios';
import PageLoader from "./Loader/PageLoader";
import { useNavigate } from "react-router-dom";
import { ChatAppContext } from "../AppContext";
import Helpers from "../Config/Helpers";
import { RecursiveCharacterTextSplitter } from 'langchain/text_splitter';
import { createClient } from '@supabase/supabase-js';
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai";

const prompts = {
  "General": [
    "Provide a summary of the document.",
    "Highlight the key points from the text.",
    "Generate a concise overview."
  ],
  "Legal Summarizer": [
    "Summarize the legal arguments presented.",
    "Extract key legal precedents mentioned.",
    "Outline the main points of the legal document."
  ],
  "Report Analysis": [
    "Summarize the findings of the report.",
    "Highlight the main conclusions and recommendations.",
    "Provide an analysis of the data presented."
  ]
};

const sbUrl = process.env.REACT_APP_SBURL;
const sbApiKey = process.env.REACT_APP_SBAPI;

export default function ChatModal({ handleClose, handleShow, setShow, show }) {
  const [loader, setLoader] = useState(false);
  const [useCase, setUseCase] = useState("General");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { setChatFile, setChatid, setPromptState, setSelectedPrompt, setDocumentList } = useContext(ChatAppContext);

  useEffect(() => {
    // Set the prompt state with the initial prompts for the default use case
    setPromptState(prompts[useCase]);
  }, [useCase]);

  const handleUpload = async (file) => {

    const promptid = '1'
    console.log("haha in upload")
    if (file) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        [file].forEach((file) => formData.append("files[]", file));
        formData.append("prompt_id", promptid);

        const response = await axios.post(
          `${Helpers.apiUrl}user/save`,
          formData,
          Helpers.authFileHeaders
        );
        console.log("RESPONSE OF THE bot", response);

        const chatId = response.data.chat_id;
        setChatid(chatId)

        const textResponse = await axios.get(
          `${Helpers.apiUrl}chat/get/${chatId}`,
          Helpers.authFileHeaders
        );
        const chatData = textResponse.data.file_content;
        const splitter = new RecursiveCharacterTextSplitter({
          chunkSize: 500,
          chunkOverlap: 50,
          separators: ['\n\n', '\n', ' ', '']
        });

        console.log(textResponse);
        const output = await splitter.createDocuments([chatData]);
        const client = createClient(sbUrl, sbApiKey);
        const supaBaseRespone = await SupabaseVectorStore.fromDocuments(
          output,
          new OpenAIEmbeddings({ openAIApiKey: process.env.REACT_APP_OPENAIAPI }),
          {
            client,
            tableName: 'documents',
          }
        );
        console.log("supabaseRespone: " + supaBaseRespone);
        // Navigate to the chat page using the combined chat_id
        // navigate(`/user/chat/${chatId}`, { state: { fileName: files.map(file => file.name).join(", ") } });
        Helpers.toast("success", "File uploaded successfully");
        console.log("ChatId = ", chatId);
        return chatId

      } catch (error) {
        if (error.response) {
          Helpers.toast("error", error.response.data.message);
        } else {
          Helpers.toast("error", "Unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      Helpers.toast("error", "Can't send without file");
    }
  };

  const handleUploadButton = async (e) => {
    const file = e.target.files[0]; // Get the single file
    setDocumentList((prevList) => [...prevList, file]);

    setChatFile(file);

    if (!file) return;

    setLoader(true);

    try {
      const chat_id = await handleUpload(file)
      setLoader(false);
      navigate(`/chat/chatbot/${chat_id}`);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handlePromptStateChange = (e) => {
    setSelectedPrompt(e.target.value);
  };

  const handleUseCaseChange = (e) => {
    const selectedUseCase = e.target.value;
    setUseCase(selectedUseCase);
    setPromptState(prompts[selectedUseCase]);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} style={{ padding: "20px" }}>
        {loader ? (
          <PageLoader />
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Let's Decode this Document!: ✨</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="w-100 d-flex flex-column flex-sm-row gap-4">
                <div className="d-flex w-100 w-sm-50 flex-sm-fill flex-column gap-3">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex gap-1 align-items-center">
                      <label className="fw-medium">Select Use Case</label>
                    </div>
                    <Form.Select value={useCase} onChange={handleUseCaseChange}>
                      <option value="General">General</option>
                      <option value="Legal Summarizer">Legal Summarizer</option>
                      <option value="Report Analysis">Report Analysis</option>
                    </Form.Select>
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex gap-1 align-items-center">
                      <label className="fw-medium">Choose Prompt</label>
                    </div>
                    <Form.Select onChange={handlePromptStateChange}>
                      {prompts[useCase].map((prompt, index) => (
                        <option key={index} value={prompt}>{prompt}</option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="d-flex w-100 w-sm-50 flex-sm-fill flex-column gap-3">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex gap-1 align-items-center">
                      <label className="fw-medium">Upload Your Document</label>
                    </div>
                    <label
                      className="w-100 border-2 border-dashed border-grey d-flex justify-content-center align-items-center"
                      id="upload"
                      accept=".docx,.pdf,.txt,.pptx"
                      placeholder="Talk with DocXter"
                      htmlFor="abc"
                      style={{ height: "230px", cursor: "pointer" }}
                    >
                      <input type="file" id="abc" onChange={handleUploadButton} style={{ opacity: '0', position: 'absolute' }} />
                      <div>
                        <div style={{ borderRadius: '5px', border: '1px solid lightgrey', padding: '5px' }}>
                          Browse Files
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
}
