import { createClient } from '@supabase/supabase-js';
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai";

const openAIApiKey = process.env.REACT_APP_OPENAIAPI;

const embeddings = new OpenAIEmbeddings({openAIApiKey});
const sbUrl = process.env.REACT_APP_SBURL;
const sbApiKey = process.env.REACT_APP_SBAPI;
const client = createClient(sbUrl, sbApiKey);

const vectorStore = new SupabaseVectorStore(embeddings,{
    client,
    tableName:'documents',
    queryName:'match_documents'
});

const retriever = vectorStore.asRetriever();

export {retriever}