
import React, { createContext, useState } from 'react';

const ChatAppContext = createContext();
const ChatAppProvider = ({ children }) => {
    const [chatFile, setChatFile] = useState(null);
    const [chatid, setChatid] = useState(null);

    const [promptState, setPromptState] = useState(null);
    const [selectedprompt, setSelectedPrompt] = useState(null);
    const [documentList, setDocumentList] = useState([]);
    return (
        <ChatAppContext.Provider value={{
            chatFile, chatid, setChatid, setChatFile,
            setPromptState, promptState, setSelectedPrompt,
            documentList, setDocumentList, selectedprompt
        }}>
            {children}
        </ChatAppContext.Provider>
    );
};
export { ChatAppContext, ChatAppProvider };