import { useEffect, useState } from "react";
import Helpers from "../../Config/Helpers";
import useTitle from "../../Hooks/useTitle";
import axios from "axios";
import { Link } from "react-router-dom";
import PageLoader from '../../Components/Loader/PageLoader';

const UserDashboard = () => {
  useTitle("Dashboard");
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [loader, setLoader] = useState(true);
  const [pkgs, setPkgs] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isOrgMember, setIsOrgMember] = useState(false);
  const [isOrgOwner, setIsOrgOwner] = useState(false);
  const [dateExpire, setDateExpire] = useState();
  const [datePurchase, setDatePurchase] = useState();
  const [planType, setPlanType] = useState();
  const [members, setMembers] = useState();
  
  const fetchOrganizationName = async (orgId) => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}organizations/single/${orgId}`, Helpers.authHeaders);
      setOrganizationName(response.data.org_name);
      setLoader(false); // Stop loader once organization name is set
    } catch (error) {
      console.error("Failed to fetch organization name", error);
      setLoader(false); // Stop loader on error
    }
  };
  const getNestedData = (data) => {
    // If data is an object and has a 'data' property, recurse into it
    while (data && typeof data === 'object' && 'data' in data) {
      data = data.data;
    }
    return data;
  };
const getPackages = () => {
  axios
  .get(`${Helpers.apiUrl}packages/all-packages`, Helpers.authHeaders)
  .then((response) => {
        setPkgs(response.data.length);
        console.log(response.data.length)
      });
  };
const getPrompts = () => {
    axios
      .get(`${Helpers.apiUrl}prompt/all-prompts`, Helpers.authHeaders)
      .then((response) => {
        setPrompts(response.data.length);
        console.log(response.data.length)
      });
  };
const getTemplates = () => {
    axios
      .get(`${Helpers.apiUrl}template/all-templates`, Helpers.authHeaders)
      .then((response) => {
        
        setTemplates(getNestedData(response.data).length);
        console.log("Templates:", getNestedData(response.data))
      });
  };
  const getUserPlan = () => {
    axios
        .get(`${Helpers.apiUrl}transactions/user/latest-transaction/${Helpers.authUser.id}`, Helpers.authHeaders)
        .then((response) => {
            const data = response.data;

            if (data.code === 'NO_TRANSACTIONS') {
                console.log("No transactions found.");
                // Handle no transactions scenario here, if needed
            } else {
                const finalData = getNestedData(data);
                console.log("User Plan Data:", finalData);
                setPlanType(finalData.package_type);
                setDatePurchase(finalData.purchase_date);
                setDateExpire(finalData.expiry_date);
            }
        })
        .catch((error) => {
            console.error("Error fetching user plan:", error);
            // Handle error scenario here, if needed
        });
};
const getOrgMembers = () =>{
  axios
      .get(`${Helpers.apiUrl}members/all-members`, Helpers.authHeaders)
      .then((response) => {
        setMembers(response.data.length);
        console.log("Members of org:", response.data.length)
      });
}

  useEffect(() => {
    getPackages();
    getPrompts()
    getTemplates()
    getUserPlan()
    if(Helpers.authUser.is_org_owner){
      getOrgMembers()
    }
    axios
      .get(`${Helpers.apiUrl}user/info`, Helpers.authHeaders)
      .then((response) => {
          setTransactions(response.data.user.user_transactions)
        console.log("Transactions ",response.data.user.user_transactions)
        if (response.data.user.org_id) {
          setIsOrgMember(true)
          fetchOrganizationName(response.data.user.org_id);
        } else {
          setLoader(false); // Stop loader if user is not part of an organization
        }
        if (response.data.user.is_org_owner){
          setIsOrgOwner(true)

        }
      })
      .catch((error) => {
        console.error("Failed to fetch user info", error);
        setLoader(false); // Stop loader on error
      });
  }, []);
  if (loader) {
    return <PageLoader />;
    }


    return (
      <div className="nk-wrap">
      
        <div className="nk-content">
          <div className="container-xl">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <div className="nk-block-head nk-page-head dashboard-top">
                  <div className="nk-block-head-between">
                    <div className="nk-block-head-content align-center d-flex flex-wrap justify-content-between w-100">
                      <h2 className="display-6 mb-0 py-2 px-3 ">
                        Welcome {Helpers.authUser.name}!
                      </h2>
                      {organizationName && (
                        <h4 className=" px-3 py-2 bg-blue bg-opacity-10 rounded-4">
                          Org: {organizationName}
                        </h4>
                      )}
  
                    </div>
                  </div>
                </div>
                <div className="nk-block">
                      {(isOrgOwner) && (
                        <div className="row g-gs">
                                    <div className="col-sm-6 col-xxl-3">
                                        <div className="card card-full bg-indigo bg-opacity-10 border-0">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between mb-1">
                                                    <div className="fs-6 text-light mb-0">Total Plans</div>
                                                    <Link to="/user/pricing" className="link link-purple">See All</Link>
                                                </div>
                                                <h5 className="fs-1">{pkgs} <small className="fs-3">Plans</small></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xxl-3">
                                        <div className="card card-full bg-cyan bg-opacity-10 border-0">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between mb-1">
                                                    <div className="fs-6 text-light mb-0">Your Transactions</div>
                                                    <Link to="/user/transactions" className="link link-purple">See All</Link>
                                                </div>
                                                <h5 className="fs-1">{(transactions ? transactions : "No")} <small className="fs-3">Transactions</small></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xxl-3">
                                        <div className="card  card-full bg-purple bg-opacity-10 border-0">
                                            <div className="card-body">
                                                <h5 className="fs-1"><small className="fs-3">Plan : {planType} </small></h5>
                                                <div className="">
                                                <div className="fs-6 text-light mb-0"> <span className="fs-5 fw-bold">Purchase Date :</span>  {datePurchase} </div>
                                                <div className="fs-6 text-light mb-0"><span className="fs-5 fw-bold">Expiry Date :</span> {dateExpire}</div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xxl-3">
                                        <div className="card card-full bg-blue bg-opacity-10 border-0">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between mb-1">
                                                <h5 className="fs-1"><small className="fs-3">{organizationName} Members</small></h5>
                                                <Link to="/user/org-members" className="link link-purple">See All</Link>
                                                </div>
                                                    <div className="fs-6 text-light mb-0"><span className="fw-bold fs-5">{members}</span> Members</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                      )}
                      {(!isOrgOwner && !isOrgMember) && (
                        <div className="row g-gs">
                                    <div className="col-sm-6 col-xxl-3">
                                        <div className="card card-full bg-indigo bg-opacity-10 border-0">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between mb-1">
                                                    <div className="fs-6 text-light mb-0">Total Packages</div>
                                                    <Link to="/user/pricing" className="link link-purple">See All</Link>
                                                </div>
                                                <h5 className="fs-1">{pkgs} <small className="fs-3">Packages</small></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xxl-3">
                                        <div className="card card-full bg-cyan bg-opacity-10 border-0">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between mb-1">
                                                    <div className="fs-6 text-light mb-0">Your Transactions</div>
                                                    {(transactions > 0) && (
                                                    <Link to="/user/transactions" className="link link-purple">See All</Link>
                                                    )}
                                                </div>
                                                <h5 className="fs-1">{(transactions ? transactions : "No")} <small className="fs-3">Transactions</small></h5>
                                            </div>
                                        </div>
                                    </div>
                                    {(Helpers.authUser.user_transactions > 0) && (
                                    <div className="col-sm-6 col-xxl-3">
                                        <div className="card  card-full bg-purple bg-opacity-10 border-0">
                                            <div className="card-body">
                                                <h5 className="fs-1"><small className="fs-3">Plan : {planType} </small></h5>
                                                <div className="">
                                                    <div className="fs-6 text-light mb-0"> <span className="fs-5 fw-bold">Purchase Date :</span>  {datePurchase} </div>
                                                    <div className="fs-6 text-light mb-0"><span className="fs-5 fw-bold">Expiry Date :</span> {dateExpire}</div>
                                                </div>      
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                      )}
                                
                            </div>
                            <div className="nk-block-head">
                                <div className="nk-block-head-between">
                                    <div className="nk-block-head-content"><h2 className="display-6">Bundles</h2></div>
                                    {/* <div className="nk-block-head-content"><a href="templates.html" className="link">Explore All</a></div> */}
                                </div>
                            </div>
                            <div className="nk-block">
                                <div className="row g-gs">
                                    <div className="col-sm-6 col-xxl-3">
                                        <div className="card card-full">
                                            <div className="card-body">
                                                <div className="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3"><em className="icon ni ni-bulb-fill"></em></div>
                                                <h5 className="fs-4 fw-medium">Prompts</h5>
                                                <p className="small text-light">Number of Prompts : {prompts}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xxl-3">
                                        <div className="card card-full">
                                            <div className="card-body">
                                                <div className="position-absolute end-0 top-0 me-4 mt-4"><div className="badge text-bg-dark rounded-pill text-uppercase">New</div></div>
                                                <div className="media media-rg media-middle media-circle text-blue bg-blue bg-opacity-20 mb-3"><em className="icon ni ni-spark-fill"></em></div>
                                                <h5 className="fs-4 fw-medium">CV Templates</h5>
                                                <p className="small text-light">Number of Templates : {templates}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="nk-footer">
          <div className="container-xl">
            <div className="d-flex align-items-center flex-wrap justify-content-between mx-n3">
              <div className="nk-footer-links px-3">
                <ul className="nav nav-sm">
                  <li className="nav-item">
                    <a className="nav-link" href="../index-2.html#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="../index-2.html#">
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="../index-2.html#">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="../index-2.html#">
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="../index-2.html#">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div className="nk-footer-copyright fs-6 px-3">
                &copy; 2024 All Rights Reserved to <a href="/">DOCSPHERE.AI</a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  };
  
  export default UserDashboard;