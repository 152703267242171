import React, { useContext, useEffect, useState } from "react";
import { Card, Button, Form, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { ChatAppContext } from "../../../AppContext";
import axios from "axios";
import Helpers from "../../../Config/Helpers";


const SideBarRedesigned = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const { documentList } = useContext(ChatAppContext)
  const navigate = useNavigate();
  const [userChats, setUserChats] = useState([]);
  const handleNavigation = () => {
    navigate('/user/dashboard');
  };

  const NavigateToChatPage = (chats) => {
    console.log("Chat ID in NavigateToChatPage = ", chats.chat_id)
    navigate(`/chat/chatbot/${chats.chat_id}`);
  }
  const getPreviousChats = async () => {
    try {
      const userString = localStorage.getItem("user");
      if (userString) {
        const user = JSON.parse(userString);
        const user_id = user.id;
        const user_chats = await axios.get(`${Helpers.apiUrl}chat/all`, Helpers.authHeaders)
        if (user_chats) {
          setUserChats(
            user_chats.data.map(datum => ({
              "chat_id": datum.chatid,
              "file": JSON.parse(datum.file)[0] || ""
            }))
          );
        }
        else {
          console.log("No Chats")
        }
      } else {
        console.log("No user found in localStorage");
      }

    } catch (e) {
      console.log("Error: ", e);
    }
  };

  useEffect(() => {
    getPreviousChats();
  }, []);


  documentList.forEach(file => {
    console.log(file.name);
  })

  const fileName = documentList[0]?.name;
  console.log("filename", fileName);
  const [documents, setDocuments] = useState([
    "Document 1",
    "Document 2",
  ]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDocuments = documents?.filter((doc) =>
    doc.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-2">
      <div >
        <a href="/chat/chat-interface">
          <button
            className="btn btn-dark text-white text-start d-flex gap-3 w-100 rounded-3 px-1 py-1"
            type="button"
            aria-haspopup="dialog"
            aria-expanded="false"
            aria-controls="radix-:r0:"
            data-state="closed"
          >
            <span>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 3.83331V13.1666M3.33334 8.49998H12.6667"
                  stroke="white"
                  strokeWidth="1.33"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
            <span className="d-inline">New Chat</span>
          </button>
        </a>
      </div>
      <div className=" my-2">
        <input
          type="text"
          className="form-control"
          placeholder="Quick Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className="pt-2">
        <Accordion style={{ width: "250px" }}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Documents</Accordion.Header>
            <Accordion.Body>
              <ListGroup>
                {userChats.map((chats, index) => (
                  <ListGroup.Item key={index} className="fit-content">
                    <div onClick={() => NavigateToChatPage(chats)} className="d-flex align-items-center  mt-1 cursor-pointer text-small hover:bg-secondary transition-all rounded-3 ">
                      <div className="text-truncate">{chats.file}</div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

      </div>

      <div onClick={handleNavigation} className="text-start mt-2 py-1 pl-4 rounded-md text-lg text-black cursor-pointer border-black-100 border-1">
        Home
      </div>
    </div>
  );
};

export default SideBarRedesigned;
