import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Helpers from '../Config/Helpers';

const PaymentCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const trxref = query.get('trxref');
    const reference = query.get('reference');

    if (trxref && reference) {
      verifyPayment(trxref, reference);
    }
  }, [location]);

  const verifyPayment = async (trxref, reference) => {
    try {
      const response = await axios.post(`${Helpers.apiUrl}verify-payment`, { trxref, reference }, Helpers.authHeaders);
      console.log('Payment verification response:', response.data);
      if (response.data.status === 'success') {
        Helpers.toast("success", "Payment verified successfully");
      } else {
        Helpers.toast("error", "Payment verification failed");
      }
    } catch (error) {
      Helpers.toast("error", "Error verifying payment");
      console.error('Error verifying payment:', error.message);
    }
  };

  return <div>Verifying Payment...</div>;
};

export default PaymentCallback;
