import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import axios from "axios";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
const UserLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isCompact, setIsCompact] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [planDetails, setPlanDetails] = useState();
  const [hasValidTransaction, setHasValidTransaction] = useState(false);
  const [hasAnyTransaction, setHasAnyTransaction] = useState(false);
  const [orgId, setorgId] = useState();
  const [orgOwner, setOrgOwner] = useState();

  const toast = (message) => {
    const notyf = new Notyf();
    notyf.open({
      message: message,
      type: 'error',
      position: { x: "right", y: "top" },
      ripple: true,
      dismissible: true,
      duration: 0,
    });
  };

  const logout = (e) => {
    e.preventDefault();
    Helpers.toast("success", "Logged out successfully");
    localStorage.clear();
    navigate("/");
  };

  const checkPackageValidty = () => {
    axios
      .get(`${Helpers.apiUrl}user/info`, Helpers.authHeaders)
      .then((response) => {
        setorgId(response.data.user.org_id);
        setOrgOwner(response.data.user.is_org_owner);
        console.log("Org Number", orgId);
        console.log("Org Status", orgOwner);
        if (response.data.user.org_id) {
          axios
            .get(`${Helpers.apiUrl}organizations/single/${response.data.user.org_id}`, Helpers.authHeaders)
            .then((response) => {
              if (response.data.expired == 1) {
                toast("Your Package has been expired, Please buy it again");
              }
            });
        } else {
          if (response.data.user.expired == 1) {
            toast("Your Package has been expired, Please buy it again");
          }
        }
      })
      .catch((error) => {
        Helpers.toast("error", error.response.data.message);
      });
  };

  const fetchPlanDetails = () => {
    axios
      .get(`${Helpers.apiUrl}transactions/user/${Helpers.authUser.id}`, Helpers.authHeaders)
      .then((response) => {
        const transactions = response.data;
        const hasValidTransaction = transactions.some(transaction => {
          return new Date(transaction.expiry_date) > new Date();
        });
        setHasValidTransaction(hasValidTransaction);
        const hasAnyTransaction = transactions.length > 0;
        setHasAnyTransaction(hasAnyTransaction);
      })
      .catch((error) => {
        Helpers.toast("error", error.response.data.message);
      });
  };

  useEffect(() => {
    Helpers.toggleCSS();
    setShowMobileNav(false);
    checkPackageValidty();
    fetchPlanDetails();
  }, [location.pathname]);

  const sidebarMenu = [
    {
      path: "/user/dashboard",
      icon: "ni ni-dashboard-fill",
      text: "Dashboard"
    },
    {
      path: "/chat/chatbot",
      icon: "ni ni-chat-round",
      text: "Chatbot"
    },
    {
      path: "/user/templates-library",
      icon: "ni ni-layers",
      text: "Templates Library"
    },
    {
      path: "/user/chat-history",
      icon: "ni ni-clock",
      text: "History"
    },
    ...((!orgId || orgOwner)
      ? [
        {
          path: "/user/pricing",
          icon: "ni ni-layers",
          text: "Pricing"
        }
      ]
      : []),
    ...(orgId && orgOwner
      ? [
        {
          path: "/user/transactions",
          icon: "ni ni-money",
          text: "Transactions"
        },
        {
          path: "/user/org-members",
          icon: "ni ni-users",
          text: "Org Members"
        }
      ]
      : []),
    ...(hasAnyTransaction && !orgId
      ? [
        {
          path: "/user/transactions",
          icon: "ni ni-money",
          text: "Transactions"
        }
      ]
      : [])
  ];

  return (
    <div className="nk-app-root" data-sidebar-collapse="lg">



      <>
        <div className="nk-main">
          {showMobileNav && (
            <div
              onClick={() => setShowMobileNav(false)}
              className="sidebar-overlay"
            ></div>
          )}
          <div
            className={`nk-sidebar nk-sidebar-fixed ${isCompact && "is-compact"} ${showMobileNav && "sidebar-active"
              }`}
            id="sidebar"
          >
            <div className="nk-compact-toggle">
              <button
                onClick={() => setIsCompact(!isCompact)}
                className="btn btn-xs btn-outline-light btn-icon compact-toggle text-light bg-white rounded-3"
              >
                <em
                  className={`icon off ni ${isCompact ? "ni-chevron-right" : "ni-chevron-left"
                    }`}
                ></em>
              </button>
            </div>
            <div className="nk-sidebar-element nk-sidebar-head">
              <div className="nk-sidebar-brand">
                <a href="/user/dashboard" className="logo-link">
                  <div className="logo-wrap">
                    <img
                      className="logo-img logo-light dashboardlogo"
                      src="/app/logo.png"
                      alt=""
                    />
                    <img
                      className="logo-img logo-dark dashboardlogo"
                      src="/app/logo.png"
                      alt=""
                    />
                    <img
                      className="logo-img logo-icon dashboardlogo"
                      src="/app/logo.png"
                      alt=""
                    />
                  </div>
                </a>
              </div>
            </div>
            <div className="nk-sidebar-element nk-sidebar-body">
              <div className="nk-sidebar-content h-100" data-simplebar>
                <div className="nk-sidebar-menu">
                  <ul className="nk-menu">
                    {sidebarMenu.map((item, index) => (
                      <li key={index} className="nk-menu-item">
                        <Link
                          to={item.path}
                          className={`nk-menu-link ${location.pathname === item.path ? 'active' : ''}`}
                        >
                          <span className="nk-menu-icon">
                            <em className={`icon ${item.icon}`}></em>
                          </span>
                          <span className="nk-menu-text">{item.text}</span>
                        </Link>
                      </li>
                    ))}
                    <li className="nk-menu-item">
                      <a href="#!" onClick={logout} className="nk-menu-link">
                        <span className="nk-menu-icon">
                          <em className="icon ni ni-signout"></em>
                        </span>
                        <span className="nk-menu-text">Sign Out</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="nk-sidebar-element nk-sidebar-footer">
              <div className="nk-sidebar-footer-extended pt-3">
                <div className="border border-light rounded-3">

                  <Link
                    className="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3"
                    to={"/user/profile"}
                  >
                    <div className="media-group">
                      <div className="media media-sm media-middle media-circle text-bg-primary">
                        <img
                          src={Helpers.serverImage(Helpers.authUser.profile_pic)}
                        />
                      </div>
                      <div className="media-text">
                        <h6 className="fs-6 mb-0">{Helpers.authUser.name}</h6>
                        <span className="text-light fs-7">
                          {Helpers.authUser.email}
                        </span>
                      </div>
                      <em className="icon ni ni-chevron-right ms-auto ps-1"></em>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-wrap">
            <div className="nk-header nk-header-fixed">
              <div className="container-fluid">
                <div className="nk-header-wrap">
                  <div className="nk-header-logo ms-n1">
                    <div className="nk-sidebar-toggle me-1">
                      <button
                        onClick={() => setShowMobileNav(true)}
                        className="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none"
                      >
                        <em className="icon ni ni-menu"> </em>
                      </button>
                      <button
                        onClick={() => setShowMobileNav(true)}
                        className="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex"
                      >
                        <em className="icon ni ni-menu"> </em>
                      </button>
                    </div>
                  </div>
                  <div className="nk-header-tools">
                    <ul className="nk-quick-nav ms-2">
                      <li className="dropdown d-inline-flex">
                        <Link className="d-inline-flex" to={"/user/profile"}>
                          <div className="media media-sm media-middle media-circle text-bg-primary">
                            <img
                              className="chat-avatar"
                              src={Helpers.serverImage(
                                Helpers.authUser.profile_pic
                              )}
                              alt=""
                            />
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {organizationName && (
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Org: {organizationName}</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Outlet />
          </div>
        </div>
      </>

    </div>
  );
};

export default UserLayout;
