import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Helpers from "../../Config/Helpers";
import dayjs from 'dayjs';
import { useNavigate, useLocation } from "react-router-dom";
import PageLoader from '../../Components/Loader/PageLoader';

const PricingPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loader, setLoader] = useState(true);
  const [plans, setPlans] = useState(null);
  const [shouldBuyPlan, setShouldBuyPlan] = useState(true);
  const [organizationName, setOrganizationName] = useState('');
  const [isOrganization, setIsOrganization] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [noPermission, setNoPermission] = useState(false);
  const [previousPakcage, setPreviousPackage] = useState({})
  const [renewLoader, setRenewLoader] = useState(false)
  const [userTransactions, setUserTransactions] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const _ = require("lodash");

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setIsOrganization(plan.package_type === 'Organization');
  };
  const getNestedData = (data) => {
    // If data is an object and has a 'data' property, recurse into it
    while (data && typeof data === 'object' && 'data' in data) {
      data = data.data;
    }
    return data;
  };



  //   {
  //     "user_id": 35,
  //     "package_id": 3,
  //     "purchase_date": "2024-06-10"
  // }

  const handleBuyPlan = async () => {
    let data;
    const currentDate = new dayjs();
    if (selectedPlan.package_type === 'Organization') {
      data = {
        user_id: Helpers.authUser.id,
        package_id: selectedPlan.id,
        amount: selectedPlan.package_price,
        purchase_date: currentDate.format("YYYY-MM-DD"),
        org_name: organizationName,
      };
    } else {
      data = {
        user_id: Helpers.authUser.id,
        package_id: selectedPlan.id,
        amount: selectedPlan.package_price,
        purchase_date: currentDate.format("YYYY-MM-DD"),
      };
    }

    localStorage.setItem('selectedPlan', JSON.stringify(selectedPlan));
    localStorage.setItem('actionType', 'buyPlan');
    if (organizationName) {
      localStorage.setItem('organizationName', organizationName);
    }
    setLoader(true);
    try {
      const response = await axios.post(`${Helpers.apiUrl}pay`, { amount: data.amount }, Helpers.authHeaders);
      const finalData = getNestedData(response.data);
      window.location.href = finalData.authorization_url;
      // Helpers.toast("success", "Payment Happening successfully");
      setLoader(false);
    } catch (error) {
      Helpers.toast("error", error);
      // console.error('Error purchasing plan:', error.message);
      setLoader(false);
    }
  };

  // /transactions/renew-transaction
  const handleRenewPlans = async () => {
    // Filter the plans based on the condition


    const currentDate = new dayjs().format('YYYY-MM-DD')
    const data = {
      user_id: Helpers.authUser.id,
      package_id: selectedPlan.id,
      amount: selectedPlan.package_price,
      purchase_date: currentDate
    }
    localStorage.setItem('selectedPlan', JSON.stringify(selectedPlan));
    localStorage.setItem('actionType', 'renewPlan');
    setRenewLoader(true);
    try {
      const response = await axios.post(`${Helpers.apiUrl}pay`, { amount: data.amount }, Helpers.authHeaders);
      const finalData = getNestedData(response.data);
      window.location.href = finalData.authorization_url;
      // Helpers.toast("success", "Payment Happening successfully");
      setRenewLoader(false);
    } catch (error) {
      Helpers.toast("error", error);
      // console.error('Error purchasing plan:', error.message);
      setRenewLoader(false);
    }

  };



  const fetchPlans = async () => {
    setLoader(true);
    try {
      // Fetch user info
      const userResponse = await axios.get(`${Helpers.apiUrl}user/info`, Helpers.authHeaders);
      const userInfo = userResponse.data.user;
  
      // Fetch plans
      const response = await axios.get(`${Helpers.apiUrl}packages/all-packages`, Helpers.authHeaders);
      const plans = response.data;
  
      const filteredPlans = plans.filter(plan => {
        if (userInfo.is_org_owner === 1) {
          return plan.package_type !== "Single User";
        } else if (userInfo.org_id == null) {
          console.log(userTransactions)
          if (userInfo.user_transactions != 0) {
            return plan.package_type === "Single User";
          } else {
            return true;
          }
        }
  
        return true;
      });
      console.log("allowed plans", filteredPlans);
      setPlans(_.reverse(filteredPlans));
  
    } catch (error) {
      console.error('Error fetching plans:', error);
      Helpers.toast("error", error.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  // trasactions/user/id


  const fetchPrevPkg = async () => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}transactions/user/${Helpers.authUser.id}`, Helpers.authHeaders);
      console.log("Previous Package", response)
      const sortedData = response.data.sort((a, b) => new Date(b.purchase_date) - new Date(a.purchase_date));
      if (sortedData.length == 0) {
        setPreviousPackage(prevState => ({
          ...prevState,
          pkgId: false,
        }));
      }
      setPreviousPackage(prevState => ({
        ...prevState,
        pkgId: sortedData[0]?.package_id,
        pkgIdType: sortedData[0]?.package_type
      }));
      // setPreviousPackage(sortedData[0].purchase_date);
      // setPreviousPackage(4);


    } catch (error) {
      Helpers.toast("error", error.response.data.message);

    }
  };





  const checkPackageValidity = () => {
    setLoader(true);
    axios
      .get(`${Helpers.apiUrl}user/info`, Helpers.authHeaders)
      .then((response) => {
        setUserTransactions(response.data.user.user_transactions)
        if (response.data.user.org_id) {
          axios
            .get(`${Helpers.apiUrl}organizations/single/${response.data.user.org_id}`, Helpers.authHeaders)
            .then((orgResponse) => {
              if (orgResponse.data.expired == 1) {
                setIsExpired(true);
              } else {
                setIsExpired(false);
                // setShouldBuyPlan(false);
              }
            });
        } else {
          if (response.data.user.expired == 1) {

            setIsExpired(true);
          } else if (response.data.user.expired == 0) {
            setIsExpired(false);
            // setShouldBuyPlan(false);
          }
        }
        setNoPermission(response.data.user.permissions == 1);
      })
      .catch((error) => {
        Helpers.toast("error", error.response.data.message);
      });
  };


  const verifyPayment = async (trxref, reference) => {
    try {
      const response = await axios.post(`${Helpers.apiUrl}verify-payment`, { trxref, reference }, Helpers.authHeaders);
      // console.log('Payment verification response:', response.data);
      if (response.data.status === 'success') {
        // Helpers.toast("success", "Payment verified successfully");
        const orgName = localStorage.getItem('organizationName');
        const selectedPlan = JSON.parse(localStorage.getItem('selectedPlan'));
        const actionType = localStorage.getItem('actionType'); // Retrieve action type
        const currentDate = dayjs().format('YYYY-MM-DD');
        const data = {
          user_id: Helpers.authUser.id,
          package_id: selectedPlan.id,
          amount: selectedPlan.package_price,
          purchase_date: currentDate,
          org_name: orgName ? orgName : undefined, // Add org_name if it exists
        };
        if (actionType === 'buyPlan') {
          try {
            const response = await axios.post(`${Helpers.apiUrl}transactions/save-transaction`, data, Helpers.authHeaders);
            Helpers.toast("success", "Plan selected successfully");
            navigate('/user/dashboard');
            // window.location.reload(); // Commented out for debugging
          } catch (error) {
            Helpers.toast("error", error.response.data.message);
            console.error('Error purchasing plan:', error.message);
          }
        } else if (actionType === 'renewPlan') {
          try {
            const response = await axios.post(`${Helpers.apiUrl}transactions/renew-transaction`, data, Helpers.authHeaders);
            console.log('Response:', response.data);
            // Handle the response as needed
            setShouldBuyPlan(false);
            Helpers.toast("success", "Plan renewed successfully");
            navigate('/user/dashboard');
            // window.location.reload(); // Commented out for debugging
          } catch (error) {
            Helpers.toast("error", error.response.data.message);
            console.error('Error renewing plan:', error.message);
            setRenewLoader(true);
          }
        }
      } else {
        Helpers.toast("error", "Payment verification failed");
      }
    } catch (error) {
      Helpers.toast("error", "Error verifying payment");
      console.error('Error verifying payment:', error.message);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const trxref = query.get('trxref');
    const reference = query.get('reference');

    if (trxref && reference) {
      verifyPayment(trxref, reference);
    }


    checkPackageValidity();
    fetchPrevPkg()
    fetchPlans();
    setTimeout(() => {

      setLoader(false)
    }, 8500)
  }, []);

  return (
    <div className="nk-content">
      <div className="container-xl">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block-head nk-page-head">
              <div className="nk-block-head-between">
                <div className="nk-block-head-content">

                  <h2 className="display-6">Pricing Plans</h2>
                  <p>
                    Generate unlimited copy 10X faster with our cost-effective plan to write blog posts, social media ads and many more.
                  </p>
                </div>


              </div>
            </div>

            {
              loader ? <PageLoader /> : (
                <div className="nk-block">
                  <div className="card mt-xl-5">
                    <div className="row g-0">
                      {plans?.map((plan) => (
                        <div className="col-xl-4 mb-4" key={plan?.id} onClick={userTransactions && !isExpired ? null : () => handlePlanSelect(plan)}>
                          <div className={`pricing card ${selectedPlan?.id === plan.id ? 'selected' : ''} bg-white rounded h-100`} style={userTransactions && !isExpired ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
                            <div className="pricing-content py-5 text-center card-body d-flex flex-column justify-content-between">
                              <div>
                                <h5 style={{ "textAlign": "left !important" }} className="fw-normal text-dark">{plan?.package_name}</h5>
                                <h2 className="">{plan?.package_type}</h2>
                                {plan.package_type === "Organization" ? (
                                  <h3 className="">Members: {plan?.package_users}</h3>
                                ) : null}
                                <div className="pricing-price-wrap">
                                  <div className="pricing-price">
                                    <h3 className="display-4 mb-1 fw-semibold">${plan?.package_price}</h3>
                                  </div>
                                </div>
                                <ul className="pricing-description list-unstyled">
                                  {plan?.package_description?.split(',').map((item, index) => (
                                    <li key={index}><i className='ni ni-check-circle'></i> {item.trim()}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* {console.log("package info ", ( isOrganization && !previousPakcage.pkgId && (isExpired || !userTransactions)))} */}
                  {isOrganization && !previousPakcage.pkgId && (isExpired || !userTransactions) && (
                    <div className="mt-4">
                      <label htmlFor="organizationName">Organization Name</label>
                      <input
                        type="text"
                        id="organizationName"
                        className="form-control"
                        value={organizationName}
                        onChange={(e) => setOrganizationName(e.target.value)}
                      />
                    </div>
                  )}

                  <div className="mt-5 d-flex justify-content-center">
                    {shouldBuyPlan && !previousPakcage?.pkgId && (
                      <button
                        className="btn btn-primary"
                        onClick={handleBuyPlan}
                        disabled={previousPakcage.pkgId || selectedPlan == null ? true : false}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        Buy Plan
                      </button>
                    )}
                    {previousPakcage && shouldBuyPlan && isExpired && (
                      <button
                        className="btn btn-primary"
                        onClick={handleRenewPlans}
                        disabled={renewLoader}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        Renew Plan
                      </button>
                    )}
                  </div>
                  <div className="mt-5">
                    <h5>Want to learn more about our pricing &amp; plans?</h5>
                    <p>
                      Read our <a href="pricing-plans.html">Plans</a>, <a href="#">Billing &amp; Payment</a>, <a href="#">FAQs</a> to learn more about our service.
                    </p>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlans;