import axios from "axios";
import useTitle from "../../Hooks/useTitle";
import { useEffect, useState } from "react";
import Helpers from "../../Config/Helpers";
import PageLoader from "../../Components/Loader/PageLoader";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../../Components/Input";

const TemplatesLibrary = () => {
  useTitle("Templates Library");
  const navigate = useNavigate();
  const { msgid } = useParams();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [template, setTemplates] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [file, setFile] = useState(null);
  const [templateid, setTemplateid] = useState(null);
  const [instructions, setInstructions] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showFormTemplate, setShowFormTemplate] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [noPermission, setNoPermission] = useState(false);

  
  const [modalContent, setModalContent] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);


  const checkPackageValidity = () => {
    axios
      .get(`${Helpers.apiUrl}user/info`, Helpers.authHeaders)
      .then((response) => {
        if (response.data.user.org_id) {
          axios
            .get(`${Helpers.apiUrl}organizations/single/${response.data.user.org_id}`, Helpers.authHeaders)
            .then((orgResponse) => {
              if (orgResponse.data.expired == 1) {
                setIsExpired(true);
              }
            });
        } else {
          if (response.data.user.expired == 1) {
            setIsExpired(true);
          }
        }
        setNoPermission(response.data.user.permissions == 1);
      })
      .catch((error) => {
        Helpers.toast("error", error.response.data.message);
      });
  };

  const getCategories = () => {
    axios
      .get(`${Helpers.apiUrl}category/all`, Helpers.authHeaders)
      .then((response) => {
        setCategories(response.data);
      });
  };

  const getNestedData = (data) => {
    while (data && typeof data === 'object' && 'data' in data) {
      data = data.data;
    }
    return data;
  };

  const handleCancel = () => {
    setIsEditing(false);
    setTemplateid(null);
    setFile(null);
    setInstructions(null);
    setShowFormTemplate(false);
    setDocumentUrl(null);
  };

  const sendTemplate = async (e) => {
    e.preventDefault();
    if (!file) {
      setErrors({ file: "Please select a file." });
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", templateid);

    try {
      setPageLoading(true);
      const response = await axios.post(
        `${Helpers.apiUrl}template/generate/template`,
        formData,
        Helpers.authFileHeaders
      );
      if (response.data.url) {
        setDocumentUrl(response.data.url);
      Helpers.toast("success", "Template Generated successfully");
      }
      setIsLoading(false);
      setPageLoading(false);
    } catch (error) {
      console.log("Template generated Failed:", error);
      setIsLoading(false);
      setPageLoading(false);
    }
  };

  const handleClick = (temp) => {
    setModalContent(temp.preview);
    setTemplateid(temp.id);
    setDocumentUrl(null); // Reset document URL when selecting a new template
  };

  const getTemplates = () => {
    setPageLoading(true);
    axios
      .get(`${Helpers.apiUrl}template/all-templates`, Helpers.authHeaders)
      .then((response) => {
        const finalData = getNestedData(response.data);
        setTemplates(finalData);
        setFilteredTemplates(finalData);
        // setPageLoading(false);
      });
  };

  const filterTemplates = (category_id) => {
    setSelectedCategory(category_id);
    if (category_id === 0) {
      setFilteredTemplates(template);
    } else {
      let filtered = template.filter(
        (template) => template.category_id === category_id
      );
      setFilteredTemplates(filtered);
    }
  };

  const searchTemplates = (e) => {
    let value = e.target.value;
    setSearchQuery(value);
    setSelectedCategory(0);
    let filtered = template.filter((template) =>
      template.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTemplates(filtered);
  };

  useEffect(() => {
    getTemplates();
    getCategories();
    checkPackageValidity();
  }, []);
  setTimeout(() => {
    setPageLoading(false)
  }, 3500);

  return (
    <div className="nk-content">
      <div className="container-xl">
        <div className="nk-content-inner">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <div className="nk-content-body">
              {!modalContent && (
                <div>
                  <div className="nk-block-head nk-page-head">
                    <div className="nk-block-head-between">
                      <div className="nk-block-head-content">
                        <h2 className="display-6">Template Library</h2>
                        <p>Choose the template which you want to use</p>
                      </div>
                      <div className="nk-block-head-content">
                        <div className="d-flex gap gx-4">
                          <div className="">
                            <div className="form-control-wrap">
                              <div className="form-control-icon start md text-light">
                                <em className="icon ni ni-search"></em>
                              </div>
                              <input
                                type="text"
                                value={searchQuery}
                                onChange={searchTemplates}
                                className="form-control form-control-md"
                                placeholder="Search Template"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <ul className="filter-button-group mb-4 pb-1">
                      <li>
                        <button
                          className={`filter-button ${selectedCategory === 0 ? "active" : ""}`}
                          onClick={() => filterTemplates(0)}
                          type="button"
                          data-filter="*"
                        >
                          All
                        </button>
                      </li>
                      {categories.map((category) => (
                        <li key={category.id}>
                          <button
                            className={`filter-button ${selectedCategory === category.id ? "active" : ""}`}
                            onClick={() => filterTemplates(category.id)}
                            type="button"
                          >
                            {category.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                    {console.log(isExpired)}
                    <div className="row g-gs filter-container" data-animation="true" style={isExpired || noPermission ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
                      {filteredTemplates.map((temp) => (
                        <div
                          className="col-sm-4 col-xxl-3 filter-item blog-content"
                          key={temp.id}
                        >
                          <a onClick={() => handleClick(temp)}>
                            <div className="card card-full shadow-none" style={{}}>
                              <div className="card-body" style={{ width: 'auto', height: '350px', overflow: 'hidden' }}>
                                <img src={`${temp.preview}`} alt="Preview" style={{ width: '100%', height: '100%' }} />
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {modalContent && (
                <div className="modal-overlay" onClick={() => setModalContent(null)}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }} className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <img src={modalContent} alt="Template Preview" style={{ width: '45%', height: 'auto' }} />
                    {documentUrl ? (
                      <div style={{ width: '45%', height: 'auto' }}>
                      <div className="d-flex gap-2">

                        <button
                          className="btn btn-primary mt-3"
                          onClick={() => window.open(documentUrl, '_blank')}
                        >
                          View Document
                        </button>
                        {/* <button
                          className="btn btn-secondary mt-3"
                          onClick={() => window.location.href = documentUrl}
                          download
                        >
                          Download Document
                        </button> */}
                      </div>
                      </div>
                    ) : (
                      <form style={{ width: '45%', height: 'auto' }} encType="multipart/form-data" onSubmit={sendTemplate}>
                        <h3>Upload Your CV File</h3>
                        <h4>PDF / DOCX</h4>
                        <div className="form-group mt-3">
                          <input
                            type="file"
                            className="form-control"
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                          {errors.file && <div className="text-danger">{errors.file}</div>}
                        </div>
                        <button
                          type="submit"
                          className="btn w-25 btn-primary mt-3"
                          disabled={isLoading}
                        >
                          {isLoading ? 'Sending...' : 'Send'}
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplatesLibrary;
