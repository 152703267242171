import { useEffect, useRef, useState, useContext } from "react";
import { PromptTemplate } from "@langchain/core/prompts";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { ChatOpenAI } from "@langchain/openai";
import ChatGPTFormatter from "../../../Components/ChatgptFormatter";
import { combineDocs } from "../../../Components/combineDocs";
import PageLoader from "../../../Components/Loader/PageLoader";
import { retriever } from "../../../Components/retriever";
import Helpers from "../../../Config/Helpers";
import { ChatAppContext } from "../../../AppContext";
import MarkdownIt from "markdown-it";
import { jsPDF } from "jspdf";
import {
    RunnableSequence,
    // RunnablePassthrough,
} from "@langchain/core/runnables";
const short = require('short-uuid');

const sbUrl = process.env.REACT_APP_SBURL;
const sbApiKey = process.env.REACT_APP_SBAPI;
const openAiApiKey = process.env.REACT_APP_OPENAIAPI;
const md = new MarkdownIt();

const Chatbot = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const inputRef = useRef();
    const [pageLoading, setPageLoading] = useState(false);
    const [chat, setChat] = useState({});
    const [isDone, setIsDone] = useState(true);
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [buttons, setButtons] = useState([]);
    const [isFirstResponse, setIsFirstResponse] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [noPermission, setNoPermission] = useState(false);
    const { chatFile, setChatFile } = useContext(ChatAppContext);
    const { chat_id } = useParams();
    const chatContainer = useRef(null);

    const scrollToBottom = () => {
        if (chatContainer.current) {
            chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
        }
    };

    const isHistory = location.state ? location.state.isHistory : null;

    const downloadSingleMessagePDF = (message) => {
        const pdf = new jsPDF();
        const margins = { top: 10, bottom: 10, left: 10, right: 10 };
        const pageHeight = pdf.internal.pageSize.height;
        const lineHeight = 10;
        let cursorY = margins.top;

        const splitText = pdf.splitTextToSize(
            message,
            pdf.internal.pageSize.width - margins.left - margins.right
        );

        splitText.forEach((line) => {
            if (cursorY + lineHeight > pageHeight - margins.bottom) {
                pdf.addPage();
                cursorY = margins.top;
            }
            pdf.text(margins.left, cursorY, line);
            cursorY += lineHeight;
        });

        pdf.save("download.pdf");
    };

    const template = (id) => {
        navigate(`/user/templates-library/${Helpers.encryptString(id)}`);
    };

    const handleDownload = async (msg) => {
        try {
            const response = await axios.post(
                `${Helpers.apiUrl}user/generateDocx`,
                { msg: msg },
                Helpers.authHeaders
            );
            const downloadElement = document.createElement("a");
            downloadElement.href = `${Helpers.basePath}/${response.data.filePath}`;
            downloadElement.download = "generated_doc.docx";
            downloadElement.target = "_blank";
            document.body.appendChild(downloadElement);
            downloadElement.click();
            delfile(response.data.filePath);
            document.body.removeChild(downloadElement);
        } catch (error) {
            console.error("Error generating DOCX:", error);
        }
    };

    const delfile = async (chatFile) => {
        try {
            const response = await axios.post(
                `${Helpers.apiUrl}user/deletefile`,
                { file: chatFile },
                Helpers.authHeaders
            );
        } catch (error) {
            console.error(error);
        }
    };

    const getChat = () => {
        if (chat_id !== undefined) {
            setIsDone(false);
            setPageLoading(true);
            axios
                .get(`${Helpers.apiUrl}chat/get/${chat_id}`, Helpers.authHeaders)
                .then((response) => {
                    setChat(response.data);
                    setPageLoading(false);
                    if (response.data.messages.length === 0) {
                        Helpers.toast("success", "Let's get started with your interaction");
                    } else {
                        setMessages(response.data.messages);
                    }

                })
                .catch((error) => {
                    console.log("Error: ", error);
                    setPageLoading(false);
                    if (error.response) {
                        Helpers.toast("error", "Hello World");
                    } else {
                        Helpers.toast("error", "An error occurred while fetching the chat.");
                    }
                });
        }
    };

    const getResponse = async () => {
        const btnPrompt = "";
        if (btnPrompt || userInput) {
            setIsLoading(true);

            let msg = {
                message: btnPrompt ? btnPrompt : userInput,
                user_id: Helpers.authUser.id,
                chat_id: chat_id,
                is_bot: 0,
            };
            setMessages(old => [...old, msg]);


            let finalPrompt = "";
            const logPrompt = (prompt) => {
                finalPrompt = prompt;
                return prompt;
            };

            const llm = new ChatOpenAI({ openAIApiKey: process.env.REACT_APP_OPENAIAPI });
            const standAloneTemplate = `Given a question, convert the question to a standalone question. 
        Question: {question}
        standalone question:`;
            const standAlonePrompt = PromptTemplate.fromTemplate(standAloneTemplate);

            const answerTemplate = `You are a helpful and enthusiastic support bot who can answer a given question based on the context provided. Try to find the answer in the context. If you really don't know the answer, say "I'm sorry, I don't know the answer to that." And direct the questioner to email help@docsphere.com. Don't try to make up an answer. Always speak as if you were chatting to a friend.
        context: {context}
        question: {question}
        answer:`;

            const answerPrompt = PromptTemplate.fromTemplate(answerTemplate);

            const standAloneQuestionChain = standAlonePrompt
                .pipe(logPrompt)
                .pipe(llm)
                .pipe(new StringOutputParser());

            const retrieverChain = RunnableSequence.from([
                (prevResult) => prevResult.standalone_question,
                retriever,
                combineDocs,
            ]);

            const answerChain = answerPrompt
                .pipe(logPrompt)
                .pipe(llm)
                .pipe(new StringOutputParser());

            const simulateChain = async () => {
                const standaloneQuestionResult = await standAloneQuestionChain.invoke({
                    question: userInput,
                });
                const contextResult = await retrieverChain.invoke({
                    standalone_question: standaloneQuestionResult,
                });

                const finalPromptData = await answerPrompt.format({
                    context: contextResult,
                    question: userInput,
                });

                return finalPromptData;
            };

            const finalPromptData = await simulateChain();

            const data = new FormData();

            data.append("chatid", chat_id);
            data.append("file", chatFile);
            data.append("input", btnPrompt ? btnPrompt : userInput);
            data.append("lastprompt", finalPromptData);


            addMessage();
            setUserInput("");

            const controller = new AbortController();
            const signal = controller.signal;

            try {
                fetch(`${Helpers.apiUrl}bot/response`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    body: data,
                    signal,
                })
                    .then((response) => {
                        setChatFile("");
                        if (!response.ok) {
                            response.json().then((error) => {
                                Helpers.toast("error", error.message);
                                setIsLoading(false);
                            });
                        } else {
                            const reader = response.body.getReader();
                            const decoder = new TextDecoder();
                            setIsLoading(false);

                            function processText({ done, value }) {
                                if (done) {
                                    return;
                                }
                                let text = decoder.decode(value);
                                if (text.endsWith("[DONE]")) {
                                    text = text.slice(0, -6);
                                }
                                let withLines = text.replace(/\\n/g, "\n");
                                setMessages((prevMessages) => {
                                    const updatedMessages = [...prevMessages];
                                    updatedMessages[updatedMessages.length - 1].message += withLines;
                                    return updatedMessages;
                                });

                                reader.read().then(processText);
                            }

                            reader.read().then(processText);
                        }
                        setIsFirstResponse(false);
                    })
                    .catch((error) => {
                        Helpers.toast("error in catch", 'error in catch');
                        console.log("error in :", error);
                        setIsLoading(false);
                    });
            } catch (error) {
                Helpers.toast("error", "Error in Semantic search (error in else)");
            }
        } else {
            Helpers.toast("error", "Can't send without input(error in else)");
        }
    };

    const addMessage = () => {
        let msg = {
            message: "",
            user_id: Helpers.authUser.id,
            chat_id: chat_id,
            is_bot: 1,
        };
        setMessages(old => [...old, msg]);

    };

    useEffect(() => {
        getChat();

    }, [chat_id]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <>
            <div className="nk-content" style={{ height: "calc(100vh - 30px)" }}>
                <div className="container-xl h-100 p-0">
                    <div className="nk-content-inner h-100">
                        {pageLoading ? (
                            <PageLoader />
                        ) : (
                            <div className="nk-content-body h-100  flex flex-column">
                                <div className="flex-grow-1  overflow-auto p-2" ref={chatContainer}>
                                    {isFirstResponse && !isHistory ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "24px",
                                                color: "#333",
                                                borderRadius: "10px",
                                                backgroundColor: "#ffffff",
                                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                                textAlign: "center",
                                                height: '100%',
                                            }}
                                        >
                                            Your file has been uploaded successfully. You can now
                                            enter your prompt.
                                        </div>
                                    ) : (
                                        messages.map((msg, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`container chat-box ${msg.is_bot == 0 ? "bg-white" : "bot-bubble"
                                                        }`}
                                                >
                                                    <div className="">
                                                        <div className="">
                                                            <div className="align-center">
                                                                <div className="chat-header">
                                                                    {msg.is_bot == 0 && (
                                                                        <div>
                                                                            <img
                                                                                className="chat-avatar"
                                                                                src={Helpers.serverImage(
                                                                                    Helpers.authUser.profile_pic
                                                                                )}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {msg.is_bot == 1 && (
                                                                        <div className="media media-middle media-circle text-bg-primary">
                                                                            <img src="/app/favicon.png" alt="" />
                                                                        </div>
                                                                    )}
                                                                    <span className="chat-user">
                                                                        <strong>
                                                                            {msg.is_bot == 1
                                                                                ? "DocSphere.AI"
                                                                                : msg.user_name}
                                                                        </strong>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="chat-divider"></div>
                                                        </div>
                                                        <div className="">
                                                            <div className="message">
                                                                <ChatGPTFormatter
                                                                    response={msg.message}
                                                                    writing={
                                                                        messages.length - 1 === index
                                                                    }
                                                                />
                                                            </div>
                                                            {msg.is_bot == 1 && (
                                                                <div className="d-flex justify-content-center">
                                                                    <button
                                                                        className="btn mx-1"
                                                                        style={{
                                                                            color: "#d308bd",
                                                                            border: "1px solid #d308bd",
                                                                            borderRadius: "20px",
                                                                        }}
                                                                        onClick={() =>
                                                                            downloadSingleMessagePDF(msg.message)
                                                                        }
                                                                    >
                                                                        Download PDF
                                                                    </button>
                                                                    {msg.id && (
                                                                        <button
                                                                            className="btn mx-1"
                                                                            style={{
                                                                                color: "#d308bd",
                                                                                border: "1px solid #d308bd",
                                                                                borderRadius: "20px",
                                                                            }}
                                                                            onClick={() => template(msg.id)}
                                                                        >
                                                                            Browse template
                                                                        </button>
                                                                    )}
                                                                    <button
                                                                        className="btn"
                                                                        style={{
                                                                            color: "#d308bd",
                                                                            border: "1px solid #d308bd",
                                                                            borderRadius: "20px",
                                                                        }}
                                                                        onClick={() =>
                                                                            handleDownload(md.render(msg.message))
                                                                        }
                                                                    >
                                                                        Download DOCX
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                                <div className="p-8 rounded" style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
                                    <div className="container-lg ">
                                        <div className="row">
                                            <div >
                                                <div className="form-group">
                                                    <div className="form-control-wrap">
                                                        <textarea
                                                            className="chatbot-input"
                                                            value={userInput}
                                                            onChange={(e) => setUserInput(e.target.value)}
                                                            placeholder={
                                                                isExpired
                                                                    ? "You cannot use the bot, please buy/renew the plan first"
                                                                    : noPermission
                                                                        ? "You don't have write permission."
                                                                        : "Enter a prompt here"
                                                            }
                                                            disabled={isExpired || noPermission}
                                                        ></textarea>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-control-wrap">
                                                <div className="d-flex justify-content-end text-right">
                                                    {chatFile ? (
                                                        <div></div>

                                                    ) : (
                                                        <div></div>

                                                    )}

                                                    <button
                                                        type="submit"
                                                        className="btn btn-md btn-primary"
                                                        style={{
                                                            backgroundColor: "#671BB3",
                                                            color: "#ffffff",
                                                            borderRadius: "20px",
                                                            pointerEvents: isExpired || noPermission ? "none" : "auto",
                                                            opacity: isExpired || noPermission ? 0.4 : 1,
                                                        }}
                                                        disabled={isLoading}
                                                        onClick={() => {
                                                            setUserInput("");
                                                            getResponse("")
                                                        }}
                                                    >
                                                        <span className="mx-2">Send</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chatbot;
