import React from "react";
import { Outlet } from "react-router-dom";
import SideBarRedesigned from "./SideBarRedesigned";
const ChatLayout = () => {
  return (
    <>
      <div className="fixed container-fluid custom-bg-root h-100 max-h-screen">
        <div className="h-100 d-flex">
          <div className="m-2 overflow-auto bg-white h-100 rounded-lg">
            <SideBarRedesigned />
          </div>
          <div className="col d-flex flex: 1 m-2 flex-column h-100 rounded-lg  bg-white  ">
            <Outlet />
          </div>
        </div>
      </div>

    </>
  );
};
export default ChatLayout;
